import {
  loginStart,
  loginFailure,
  loginSucces,
  logOut,
  userRegisterStart,
  userRegisterSucces,
  userRegisterFailure
} from "./userRedux";
import { publicRequest } from "../Utils/requestMethod";
import jwt_decode from "jwt-decode";
import { showRegisterModal } from "../redux/modalActions";
import { func } from "prop-types";

export const login = async (dispatch, user, history) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login/", user);
    const { token } = res.data;
    const decoded = jwt_decode(token);
    const expirationDate = new Date(decoded.exp * 1000);
    const expirationTime = (decoded.exp - decoded.iat) * 1000;
    runLogoutTimer(dispatch, expirationTime);
    localStorage.setItem("expirationDate", expirationDate);
    localStorage.setItem("jwtToken", token);
    dispatch(loginSucces(decoded, token));
  } catch (err) {
    dispatch(loginFailure());
  }
};

export const logout = async dispatch => {
  try {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("persist:root");
    localStorage.removeItem("expirationDate");
    dispatch(logOut());
  } catch (err) {
    console.log(err);
  }
};

export const register = async (dispatch, user, history) => {
  dispatch(userRegisterStart());
  try {
    const res = await publicRequest.post("/user/createUser", user);
    localStorage.setItem("showModalRegister", true);
    showRegisterModal(dispatch);
    dispatch(userRegisterSucces());
    history.push("/Landing");
    //history.push("/Landing");
    /* history.push({
      pathname: "/Landing",
      state: { detail: "response" }
    });*/
  } catch (err) {
    dispatch(userRegisterFailure());
  }
};

function runLogoutTimer(dispatch, expirationTime) {
  setTimeout(() => {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("persist:root");
    localStorage.removeItem("expirationDate");
    dispatch(logOut());
  }, expirationTime);
}
