import { React, useState, useEffect } from "react";
import styled from "styled-components";
import NavBar from "../components/NavBar";
import Annoucements from "../components/Annoucements";
import Products from "../components/Products";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Filter = styled.div`
  margin: 20px;
`;

const FilterText = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
`;

const Select = styled.select`
  padding: 10px;
`;

const Option = styled.option``;

const BrandList = () => {
  const location = useLocation();
  const category = location.pathname.split("/")[2];
  const [filter, setFilter] = useState(category);
  const [sort, setSort] = useState("nouveau");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <NavBar />
      <Annoucements />
      <Title>MARQUES DE {filter}</Title>
      <FilterContainer>
        <Filter>
          <FilterText>Filtrer : </FilterText>
          <Select value={filter} onChange={e => setFilter(e.target.value)}>
            <Option value="RESTAURATION">Restauration</Option>
            <Option value="CADEAUX">Cadeaux</Option>
            <Option value="AUTRE"> Autre</Option>
            <Option value=""> Tout</Option>
          </Select>
        </Filter>
        <Filter>
          <FilterText>Trier : </FilterText>
          <Select onChange={e => setSort(e.target.value)}>
            <Option value="nouveau">Nouveau</Option>
            <Option value="avis"> Avis</Option>
          </Select>
        </Filter>
      </FilterContainer>
      <Products category={category} filter={filter} sort={sort} />
      <Newsletter />
      <Footer />
    </Container>
  );
};

export default BrandList;
