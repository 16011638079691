import * as React from "react";
//import Modal from "@mui/material/Modal";
//import Button from "@mui/material/Button";
//import Typography from "@mui/material/Typography";
import NavBar from "../components/NavBar";
import Annoucements from "../components/Annoucements";
import Slider from "../components/Slider";
import Categories from "../components/Categories";
import Products from "../components/Products";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import Modal from "../components/Modal";
import { useDispatch, useSelector } from "react-redux";

const Home = () => {
  const { showHomeInformationModal } = useSelector(state => state.modal);
  const [open, setOpen] = React.useState(true);

  const openModal = () => {
    setOpen(prev => !prev);
  };

  return (
    <div>
      {/*<Button onClick={openModal}>Open modal</Button>*/}
      <Modal showModal={showHomeInformationModal} setOpen={setOpen} />
      <Annoucements />
      <NavBar />
      <Slider />
      <Categories />
      <Products />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Home;
