import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Routes from "./Routes";
import Home from "./pages/Home";
import BrandList from "./pages/BrandList";
import Brand from "./pages/Brand";
import Register from "./pages/Register";
import Login from "./pages/Login";
import { useSelector } from "react-redux";

const App = () => {
  const user = useSelector(state => state.user.currentUser);
  return (
    <Router>
      <Routes isAuth={user} />
    </Router>
  );
};

export default App;
