import { React, useState, useEffect } from "react";
import styled from "styled-components";
import NavBar from "../components/NavBar";
import Annoucements from "../components/Annoucements";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import RoomIcon from "@mui/icons-material/Room";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { mobile } from "../Utils/responsive";
import { useLocation } from "react-router-dom";
import { publicRequest } from "../Utils/requestMethod";
import axios from "axios";

import { backdropUnstyledClasses } from "@mui/core";

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
  padding: 50px;
  ${mobile({ flexDirection: "column", padding: "10px" })}
`;

const ImageContainer = styled.div`
  flex: 1;
`;

const Image = styled.img`
  width: 100%;
  height: 90vh;
  ${mobile({ height: "40vh" })}
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 0px 50px;
  ${mobile({ padding: "10px" })}
`;

const Title = styled.h1`
  font-weight: 400;
  margin-top: 40px;
`;

const Desc = styled.p`
  margin: 20px 0px;
  text-align: justify;
  font-size: 18px;
`;

const Lieu = styled.div`
  margin: 20px 0px;
`;

const ContactContainer = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
`;
const ContactItems = styled.span`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Phone = styled.span`
  margin: 20px 0px;
  font-size: 25px;
  font-weight: 400;
`;

const Ceo = styled.span`
  margin: 20px 0px;
  font-size: 25px;
  font-weight: 400;
`;

const Brand = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [brand, setBrand] = useState({});

  /*useEffect(() => {
    const getBrand = async () => {
      try {
        const res = await axios.get(
          " http://localhost:5000/api/brand/getBrand/6169bb586a1736bd3671f8ad"
        );
        setBrand(res.data);
        console.log(brand);
      } catch (err) {
        console.log(err);
      }
    };
    getBrand();
  }, [id]);*/
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getBrand = async () => {
      try {
        /*const res = await axios.get(
          "/api/brand/getBrand/6173d112341a8892a8fa4986"
        );*/

        //const res = await axios.get("/api/brand/getBrand/" + id);
        const res = await publicRequest.get("/brand/getBrand/" + id);
        setBrand(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getBrand();
  }, [id]);

  return (
    <Container>
      <NavBar />
      <Annoucements />
      <Wrapper>
        <ImageContainer>
          <Image src={brand.img} />
        </ImageContainer>
        <InfoContainer>
          <Title>{brand.name}</Title>
          <Desc>{brand.desc}</Desc>
          <FavoriteBorderOutlinedIcon />
          <Lieu>
            <RoomIcon style={{ marginRight: "10px" }} />
            Points de ventes :
          </Lieu>
          <ContactContainer>
            <ContactItems>
              <Phone>
                <PhoneIcon style={{ marginRight: "10px" }} />
                {brand.phone}
              </Phone>
            </ContactItems>
            <ContactItems>
              <Ceo>
                <PersonPinOutlinedIcon style={{ marginRight: "10px" }} />
                {brand.ceo}
              </Ceo>
            </ContactItems>
          </ContactContainer>
        </InfoContainer>
      </Wrapper>
      <Newsletter />
      <Footer />
    </Container>
  );
};

export default Brand;
