import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Home from "./pages/Home";
import BrandList from "./pages/BrandList";
import Brand from "./pages/Brand";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Register1 from "./pages/Register1";
import Landing from "./pages/Landing";

const Routes = ({ isAuth }) => {
  let routes = (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/Lading">
        <Landing />
      </Route>
      <Route path="/brand/:category">
        <BrandList />
      </Route>
      <Route path="/brandDetail/:id">
        <Brand />
      </Route>
      <Redirect to="/" />
    </Switch>
  );
  if (!isAuth) {
    routes = (
      <Switch>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/register1">
          <Register1 />
        </Route>
        <Route path="/Login">
          <Login />
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
  }
  return routes;
};

export default Routes;
