import React from "react";
import styled from "styled-components";
import { mobile } from "../Utils/responsive";
import { useState } from "react";
import { register } from "../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { showRegisterModal } from "../redux/modalActions";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url("https://images.unsplash.com/photo-1444653614773-995cb1ef9efa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2076&q=80")
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
  text-align: center;
`;

const Wrapper = styled.div`
  padding: 20px;
  width: 40%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  ${mobile({ width: "75%", padding: "20px" })}
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0px 0px;
  padding: 10px;
`;

const Agrement = styled.span`
  font-size: 12px;
  margin: 20px 0px;
`;

const Link = styled(NavLink)`
  color: black;
  margin: 10px 10px 0px 0px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
`;

const Error = styled.div`
  width: 100%;
  color: red;
  font-weight: 500;
  margin: 10px 0 10px 0;
`;

const Register = () => {
  //const { register, errors, handleSubmit, watch } = useForm({});
  const { isFetching, error } = useSelector(state => state.user);
  const [inputs, setInputs] = useState([]);
  const [passwordConfirm, setPasswordConfirm] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    if (error) {
      setErrorMsg("Adresse Mail existe deja!");
    }
  }, [error]);

  const handleChange = e => {
    setInputs(prev => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleClick = e => {
    e.preventDefault();
    if (inputs.password === passwordConfirm) {
      register(dispatch, inputs, history);
      /*if (error === false) {
        console.log(error);
        showRegisterModal(dispatch);
        history.push("/Landing");
      }*/
    } else {
      setErrorMsg("Les deux mots de passe ne sont pas les même!!");
    }
  };

  return (
    <Container>
      <Wrapper>
        <Title>CREER UN COMPTE </Title>
        <Form onSubmit={handleClick} autocomplete="off">
          <Input
            placeholder="Nom"
            name="firstName"
            autocomplete="false"
            onChange={handleChange}
            required
          />
          <Input
            placeholder="Prenom"
            name="lastName"
            autocomplete="off"
            onChange={handleChange}
            required
          />
          <Input
            type="email"
            placeholder="Adresse mail"
            name="email"
            autocomplete="false"
            autocomplete="off"
            onChange={handleChange}
            required
          />
          <Input
            type="password"
            placeholder="Mot de passe"
            name="password"
            onChange={handleChange}
            required
            autocomplete="off"
            autocomplete="new-password"
          />
          <Input
            type="password"
            placeholder="Confirmer mot de passe"
            autoComplete="off"
            onChange={e => setPasswordConfirm(e.target.value)}
            required
            autoComplete="new-password"
          />
          <Agrement>
            En créant votre compte, vous acceptez{" "}
            <b> nos termes et conditions </b> {"&"}
            <b> politique de confidentialité</b>
          </Agrement>
          <Button type="submit" style={{ top: "50%", left: "50%" }}>
            CREER
          </Button>
          {(error || errorMsg) && <Error>{errorMsg} </Error>}

          <Link style={{ "text-decoration": "none" }} to="/login">
            Vous avez deja un compte?
          </Link>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Register;
