import React from "react";
import styled from "styled-components";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import RoomIcon from "@mui/icons-material/Room";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { mobile } from "../Utils/responsive";
import { NavLink } from "react-router-dom";

const Container = styled.div`
  display: flex;
  ${mobile({ flexDirection: "column" })}
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const Logo = styled.h1``;

const Desc = styled.p`
  margin: 20px 0px;
  text-align: justify;
`;

const SocialContainer = styled.div`
  display: flex;
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

const Center = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ display: "none" })}
`;

const Title = styled.h3`
  margin-bottom: 30px;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  width: 50%;
  margin-bottom: 10px;
`;

const Right = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ backgroundColor: "#eee" })}
`;

const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const Link = styled(NavLink)`
  text-decoration: "none";
  color: black;
  &.active {
    color: black;
  }
`;

const Footer = () => {
  return (
    <Container>
      <Left>
        <Logo>SONGUY.</Logo>
        <Desc>
          Bienvenue sur ce site qui aura vocation à mettre en lumiere le
          formidable travail de nos entrepreuneurs locaux. Merci de partager ce
          site autour de vous et de vous abonner à la newsLetter pour rester
          informé
        </Desc>
        <SocialContainer>
          <SocialIcon color="3B5999">
            <FacebookIcon />
          </SocialIcon>
          <SocialIcon color="E4405F">
            <InstagramIcon />
          </SocialIcon>
          <SocialIcon color="55ACEE">
            <TwitterIcon />
          </SocialIcon>
        </SocialContainer>
      </Left>

      <Center>
        <Title>Liens Utils</Title>
        <List>
          <ListItem>
            <Link style={{ "text-decoration": "none" }} to="/">
              Accueil
            </Link>
          </ListItem>
          <ListItem>
            <Link
              style={{ "text-decoration": "none" }}
              to="/brand/RESTAURATION"
            >
              Restauration
            </Link>
          </ListItem>
          <ListItem>
            <Link style={{ "text-decoration": "none" }} to="/brand/CADEAUX">
              Cadeaux
            </Link>
          </ListItem>
          <ListItem>
            <Link style={{ "text-decoration": "none" }} to="/">
              Mon Compte
            </Link>
          </ListItem>
          <ListItem>
            <Link style={{ "text-decoration": "none" }} to="/">
              Conditions d'utilisation
            </Link>
          </ListItem>
        </List>
      </Center>

      <Right>
        <Title>Contact</Title>
        <ContactItem>
          <RoomIcon style={{ marginRight: "10px" }} />
          OUAGADOUGOU, Projet ZACA, SONGUY INTERTAINEMENT
        </ContactItem>
        <ContactItem>
          <PhoneIcon style={{ marginRight: "10px" }} />
          002267056XXX
        </ContactItem>
        <ContactItem>
          <EmailOutlinedIcon style={{ marginRight: "10px" }} />
          contact@songuy.com
        </ContactItem>
      </Right>
    </Container>
  );
};

export default Footer;
