import {
  showUserRegisterModal,
  closeUserRegisterModal,
  showHomeInformationModal,
  closeHomeInformationModal
} from "./modalRedux";

export const showRegisterModal = async (dispatch, user, history) => {
  try {
    dispatch(showUserRegisterModal());
  } catch (err) {
    console.log(err);
  }
};

export const closeRegisterModal = async (dispatch, user, history) => {
  try {
    dispatch(closeUserRegisterModal());
  } catch (err) {
    console.log(err);
  }
};

export const showInformationModal = async (dispatch, user, history) => {
  try {
    dispatch(showHomeInformationModal());
  } catch (err) {
    console.log(err);
  }
};

export const closeInformationModal = async (dispatch, user, history) => {
  try {
    dispatch(closeHomeInformationModal());
  } catch (err) {
    console.log(err);
  }
};
