import { React, useState, useEffect } from "react";
import styled from "styled-components";
//import { categories } from "../data";
import CategoryItems from "./CategoryItems";
import { mobile } from "../Utils/responsive";
import axios from "axios";
import { publicRequest } from "../Utils/requestMethod";

const Container = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
  ${mobile({ padding: "0px", flexDirection: "column" })}
`;

const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      try {
        //const res = await axios.get("api/brand/getAllCategories");
        const res = await publicRequest.get("brand/getAllCategories");
        setCategories(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getCategories();
  }, []);

  return (
    <Container>
      {categories.map(item => (
        <CategoryItems item={item} key={item.id} />
      ))}
    </Container>
  );
};

export default Categories;
