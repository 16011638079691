import React from "react";
import Modal from "../components/Modal";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Button = styled.button`
  min-width: 100px;
  padding: 16px 32px;
  border-radius: 4px;
  border: none;
  background: #141414;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

const Landing = () => {
  const [open, setOpen] = React.useState(false);
  const openModal = () => {
    setOpen(prev => !prev);
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <Container>
        <Button onClick={openModal}>Open modal</Button>
        <Modal showModal={open} setOpen={setOpen} />
      </Container>
    </>
  );
};

export default Landing;
