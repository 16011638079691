import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",

  initialState: {
    showUserRegisterModal: false,
    showHomeInformationModal: true
  },

  reducers: {
    showUserRegisterModal: state => {
      state.showUserRegisterModal = true;
    },
    closeUserRegisterModal: state => {
      state.showUserRegisterModal = false;
    },
    showHomeInformationModal: state => {
      state.showHomeInformationModal = true;
    },
    closeHomeInformationModal: state => {
      state.showHomeInformationModal = false;
    }
  }
});

export const {
  showUserRegisterModal,
  closeUserRegisterModal,
  showHomeInformationModal,
  closeHomeInformationModal
} = modalSlice.actions;
export default modalSlice.reducer;
