import styled from "styled-components";

const Container = styled.div`
  height: 30px;
  background-color: teal;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
`;

const Annoucements = () => {
  return <Container>Superbe promo -50% avec le code : NEWSONGY !!!</Container>;
};

export default Annoucements;
