import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import validate from "validate.js";
import { makeStyles } from "@material-ui/styles";
import { Grid, Button, TextField, Typography } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const schema = {
  prenom: {
    presence: { allowEmpty: false, message: "est obligatoire" },
    length: {
      maximum: 32
    }
  },
  nom: {
    presence: { allowEmpty: false, message: "est obligatoire" },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: "est obligatoire" },
    email: true,
    length: {
      maximum: 64
    }
  },
  phone: {
    presence: { allowEmpty: false, message: "est obligatoire" },
    length: {
      maximum: 32
    }
  },
  application: {
    presence: { allowEmpty: false, message: "est obligatoire" },
    length: {
      maximum: 32
    }
  }
};

const useStyles = makeStyles(theme => ({
  container: {
    width: "100vw",
    height: "100vh",
    background:
      'linear-gradient(rgba(255, 255, 255, 0.3),rgba(255, 255, 255, 0.3)),url("https://images.unsplash.com/photo-1444653614773-995cb1ef9efa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2076&q=80")center;',
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  wrapper: {
    padding: "20px",
    width: "40%",
    backgroundColor: "white",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    transition: "0.3s",
    borderRadius: "5px"
  },
  title: {
    fontSize: "24px",
    fontWeight: "300",
    textAlign: "center"
  },
  form: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    flex: "1",
    minWidth: "40%",
    margin: "20px 10px 0px 0px"
    //padding: "10px"
  },
  radio: {
    flex: "1",
    padding: "20px"
  },
  group: {
    width: "auto",
    height: "auto",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row"
  }
}));

const Register1 = props => {
  const classes = useStyles();

  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <Typography className={classes.title} variant="h2">
          CREER UN COMPTE
        </Typography>
        <form className={classes.form} autoComplete="off">
          <TextField
            required
            name="firstName"
            id="outlined-required"
            className={classes.textField}
            label="Prénom"
            variant="outlined"
          />
          <TextField
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off"
              }
            }}
            required
            id="outlined-required"
            className={classes.textField}
            variant="standard"
            name="lastName"
            label="Nom"
            variant="outlined"
          />
          <TextField
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off"
              }
            }}
            required
            id="outlined-required"
            className={classes.textField}
            variant="standard"
            name="email"
            label="Email"
            variant="outlined"
          />

          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off"
                }
              }}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Confirmer Password
            </InputLabel>
            <OutlinedInput
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off"
                }
              }}
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <div className={classes.Agrement}>
            En créant votre compte, vous acceptez{" "}
            <b> nos termes et conditions </b> {"&"}
            <b>politique de confidentialité</b>
          </div>
          <div Button type="submit" style={{ top: "50%", left: "50%" }}>
            CREER
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register1;
