import axios from "axios";

const BASE_URL = "/api/";
//const BASE_URL = "http://localhost:5000/api/";
const JWT_TOKEN = localStorage.getItem("jwtToken");

export const publicRequest = axios.create({
  baseURL: BASE_URL
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { Authorization: `Bearer ${JWT_TOKEN}` }
});
