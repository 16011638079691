import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { popularBrand } from "../data";
import Product from "./Product";
import axios from "axios";
import { publicRequest } from "../Utils/requestMethod";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Products = ({ category, filter, sort }) => {
  const [brands, setBrands] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    category = filter;
    const getBrands = async () => {
      try {
        /* const res = await axios.get(
          category
            ? "/api/brand/getAllBrands?category=" + category
            : "/api/brand/getAllBrands"
        );*/

        const res = await publicRequest.get(
          category
            ? "/brand/getAllBrands?category=" + category
            : "/brand/getAllBrands"
        );

        setBrands(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getBrands();
  }, [category, filter]);

  useEffect(() => {
    if (sort === "nouveau") {
      setBrands(prev =>
        [...prev].sort((a, b) => {
          ("" + b.createdAt).localeCompare(a.createdAt);
        })
      );
      //if (brands.length > 3) console.log(brands);
      // console.log(brands.sort((a, b) => {
      // ("" + b.createdAt).localeCompare(a.createdAt);
      //}));
      //setBrands(prev => [...prev].sort((a, b) => a.scoreAvis - b.scoreAvis));
    }
  }, [sort]);

  return (
    <Container>
      {brands.map(brand => (
        <Product brand={brand} key={brand.id} />
      ))}
    </Container>
  );
};

export default Products;
