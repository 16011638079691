import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",

  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
    isAuth: false,
    name: null
  },

  reducers: {
    loginStart: state => {
      state.isFetching = true;
    },
    loginSucces: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.isAuth = true;
      state.name = action.payload.firstName;
      state.currentUser = action.payload;
    },
    loginFailure: state => {
      state.isFetching = false;
      state.error = true;
    },
    logOut: state => {
      state.currentUser = null;
      state.isAuth = false;
      state.name = null;
      state.error = false;
    },

    //User Register
    userRegisterStart: state => {
      state.isFetching = true;
    },
    userRegisterSucces: state => {
      state.isFetching = false;
      state.isAuth = true;
      state.error = false;
      //state.name = action.payload.firstName;
      //state.currentUser = action.payload;
    },
    userRegisterFailure: state => {
      state.isFetching = false;
      state.error = true;
    }
  }
});

export const {
  loginStart,
  loginSucces,
  loginFailure,
  logOut,
  userRegisterStart,
  userRegisterSucces,
  userRegisterFailure
} = userSlice.actions;
export default userSlice.reducer;
