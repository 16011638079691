import React from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { mobile } from "../Utils/responsive";
import { useDispatch, useSelector } from "react-redux";
import { closeInformationModal } from "../redux/modalActions";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000 !important;
`;

const ModalWrapper = styled.div`
  width: 800px;
  height: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 10px;
  ${mobile({ width: "85%", height: "50%", display: "flex" })}
`;

const ModalImg = styled.img`
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 10px 0 0 10px;
  object-fit: cover;
  ${mobile({ display: "none" })}
`;

const ModalContent = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  p {
    margin-bottom: 1rem;
    text-align: justify;
  }
  h1 {
    ${mobile({ fontSize: "1.5rem" })}
  }
  button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  background-image: url("https://images.unsplash.com/photo-1600636214973-8ccfde1f6a67?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGNvbmZldHRpfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60");
  background-size: cover;
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

const Modal = ({ showModal, setOpen }) => {
  const dispatch = useDispatch();
  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  });

  return (
    <>
      {showModal ? (
        <Background>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <ModalImg src="https://images.unsplash.com/photo-1614529168796-cb235d6a2557?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8YWZyaWNhbiUyMG1hcmtldHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60" />
              <ModalContent>
                <h1>Êtes vous prêts ?</h1>
                <p>
                  Bienvenue sur ce site qui aura pour vocation de mettre en
                  LUMIERE le formidable travail de nos entrepreuneurs locaux.
                  Merci de partager ce site autour de vous et de vous abonner à
                  la newsLetter pour rester informé
                </p>
                <button onClick={() => closeInformationModal(dispatch)}>
                  LETS GOOO!!!!
                </button>
              </ModalContent>
              <CloseModalButton
                aria-label="close modal"
                onClick={() => closeInformationModal(dispatch)}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};

export default Modal;
