import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { mobile } from "../Utils/responsive";
import { login } from "../redux/apiCalls";
import { closeRegisterModal } from "../redux/modalActions";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
//import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.3)
    ),
    url("https://images.unsplash.com/photo-1444653389962-8149286c578a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2064&q=80")
      center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
  text-align: center;
`;

const Wrapper = styled.div`
  padding: 50px;
  width: 20%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  ${mobile({ width: "75%", padding: "20px" })}
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 10px 0px;
  padding: 10px;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
  margin-bottom: 10px;
  white-space: nowrap;
  text-align: center;
  &:disabled {
    color: green;
    cursor: not-allowed;
  }
`;

const Link = styled(NavLink)`
  color: black;
  margin: 5px 0px;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

const Error = styled.span`
  color: red;
`;

const Login = props => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector(state => state.user);
  const { showUserRegisterModal } = useSelector(state => state.modal);

  const handleClick = e => {
    e.preventDefault();
    login(dispatch, { email, password });
  };

  const handleClose = (event, reason) => {
    closeRegisterModal(dispatch);
  };

  return (
    <Container>
      <Snackbar
        open={showUserRegisterModal}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Votre compte à bien été crée!
        </Alert>
      </Snackbar>
      <Wrapper>
        <Title>CONNEXION </Title>
        <Form>
          <Input
            placeholder="Adresse mail"
            autoComplete="new-password"
            onChange={e => setEmail(e.target.value)}
          />
          <Input
            placeholder="Mot de passe"
            autoComplete="new-password"
            type="password"
            onChange={e => setPassword(e.target.value)}
          />

          <Button
            style={{ top: "50%", left: "50%" }}
            onClick={handleClick}
            disabled={isFetching}
          >
            SE CONNECTER
          </Button>
          {error && <Error> Veuillez reverifier vos identifiants ...</Error>}
          <Link style={{ "text-decoration": "none" }} to="/register">
            Mot de passe oublié ?
          </Link>
          <Link style={{ "text-decoration": "none" }} to="/register">
            Créer un compte
          </Link>
        </Form>
      </Wrapper>
    </Container>
  );
};

export default Login;
